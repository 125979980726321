import { FC } from 'react';

import { useLoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import { TwoFactorCheckStatus } from 'src/components/AccountLogin/types';

import TwoFactorCodeSender from 'src/components/AccountLogin/steps/TwoFactorStep/TwoFactorCodeSender';
import TwoFactorTotp from 'src/components/AccountLogin/steps/TwoFactorStep/TwoFactorTotp';

interface TwoFactorStepProps {
    onResendCode: () => Promise<void>;
    onNoAccessToEmail: () => void;
}

const TwoFactorStep: FC<TwoFactorStepProps> = ({ onResendCode, onNoAccessToEmail }) => {
    const { verificationType } = useLoginContext();

    if (
        verificationType === TwoFactorCheckStatus.TwoFactorTotpCheck ||
        verificationType === TwoFactorCheckStatus.TwoFactorTotpCheckWrongCode
    ) {
        return <TwoFactorTotp verificationType={verificationType} />;
    }

    return <TwoFactorCodeSender onResendCode={onResendCode} onNoAccessToEmail={onNoAccessToEmail} />;
};

export default TwoFactorStep;
