import { FC, useEffect, PropsWithChildren, ReactNode } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FormApi } from 'final-form';

import Analytics from '@hh.ru/analytics-js';

import PasswordRecovery from 'src/components/AccountLogin/components/PasswordRecovery';
import TwoFactorPopup from 'src/components/AccountLogin/components/TwoFactorPopup';
import { LoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import useLoginForm from 'src/components/AccountLogin/hooks/useLoginForm';
import Error from 'src/components/AccountLogin/steps/LoginStep/LoginError';
import LoginStep from 'src/components/AccountLogin/steps/LoginStep/LoginStep';
import MailruError from 'src/components/AccountLogin/steps/MailruErrorStep/MailruError';
import NoAccessToEmailInfo from 'src/components/AccountLogin/steps/NoAccessToEmailInfoStep/NoAccessToEmailInfoStep';
import TwoFactorStep from 'src/components/AccountLogin/steps/TwoFactorStep/TwoFactorStep';
import VerificationCodeStep from 'src/components/AccountLogin/steps/VerificationCodeStep/VerificationCodeStep';
import { LoginFormValues, LoginSteps } from 'src/components/AccountLogin/types';
import Form from 'src/components/Form';
import { useSelector } from 'src/hooks/useSelector';
import useVkAppIntegrationReady, { LOGIN_FORM } from 'src/hooks/useVkAppIntegrationReady';
import mutators from 'src/utils/forms/mutators';

import HiddenFields from 'src/components/AccountLogin/Form/HiddenFields';
import AuthMethodSwitcher from 'src/components/AccountLogin/Form/components/AuthMethodSwitcher';
import Captcha from 'src/components/AccountLogin/Form/components/Captcha';
import PasswordField from 'src/components/AccountLogin/Form/components/PasswordField';
import RememberMe from 'src/components/AccountLogin/Form/components/RememberMe';
import Submit from 'src/components/AccountLogin/Form/components/Submit';
import UsernameField from 'src/components/AccountLogin/Form/components/UsernameField';

interface VkAppWrapperProps {
    form: FormApi<LoginFormValues>;
}

const VkAppWrapper: FC<VkAppWrapperProps & PropsWithChildren> = ({ form, children }) => {
    useVkAppIntegrationReady(LOGIN_FORM, ['username'], form);
    return <>{children}</>;
};

type LoginFormProps = {
    Steps?: Partial<Record<LoginSteps, ReactNode>>;
} & ReturnType<typeof useLoginForm>;

const LoginForm: FC<LoginFormProps & PropsWithChildren> = ({
    initialValues,
    step,
    setStep,
    onPreviousStep,
    onSubmit,
    validate,
    isEmployerForm,
    twoFactorPopupUrl,
    onResetConfirmError,
    redirect,
    children,
    formRef,
    authMethod,
    actionName,
    Steps = {},
    ...restProps
}) => {
    const vacancyId = useSelector(({ postponedActions }) => postponedActions?.vacancy?.vacancyId);

    useEffect(() => {
        Analytics.sendHHEvent('element_shown', {
            elementName: isEmployerForm ? 'employer_authorization' : 'applicant_authorization',
            actionName,
            authMethod,
            vacancyId,
        });
    }, [actionName, authMethod, isEmployerForm, vacancyId]);

    const defaultSteps = {
        [LoginSteps.Login]: <LoginStep>{children}</LoginStep>,
        [LoginSteps.TwoFactorStep]: (
            <TwoFactorStep
                onResendCode={async () => {
                    await onSubmit(true);
                }}
                onNoAccessToEmail={() => setStep(LoginSteps.NoAccessToEmailStep)}
            />
        ),
        [LoginSteps.NoAccessToEmailStep]: (
            <NoAccessToEmailInfo
                backHandler={() => {
                    onResetConfirmError();
                    onPreviousStep();
                }}
            />
        ),
        [LoginSteps.MailruError]: <MailruError />,
        [LoginSteps.OtpVerification]: (
            <VerificationCodeStep onNoAccessToEmail={() => setStep(LoginSteps.NoAccessToEmailStep)} />
        ),
    };

    const stepsToRender = {
        ...defaultSteps,
        ...Steps,
    };

    return (
        <FinalForm
            initialValues={initialValues}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onSubmit={(_values: LoginFormValues) => {
                return onSubmit();
            }}
            validate={validate}
            mutators={mutators}
            render={({ handleSubmit, form }) => {
                return (
                    <LoginContext.Provider
                        value={{
                            ...restProps,
                            step,
                            setStep,
                            authMethod,
                            actionName,
                            twoFactorPopupUrl,
                            isEmployerForm,
                            username: form.getFieldState('username')?.value,
                        }}
                    >
                        <VkAppWrapper form={form}>
                            <Form
                                autoComplete="on"
                                data-qa="account-login-form"
                                method="POST"
                                noValidate
                                onSubmit={handleSubmit}
                                ref={formRef}
                            >
                                <HiddenFields />
                                {stepsToRender[step]}
                            </Form>

                            {!!twoFactorPopupUrl && (
                                <TwoFactorPopup
                                    onClose={() => redirect(twoFactorPopupUrl)}
                                    redirectUrl={twoFactorPopupUrl}
                                />
                            )}
                        </VkAppWrapper>
                    </LoginContext.Provider>
                );
            }}
        />
    );
};

type CompositionLoginFormType = {
    Username: typeof UsernameField;
    Password: typeof PasswordField;
    Captcha: typeof Captcha;
    Submit: typeof Submit;
    RememberMe: typeof RememberMe;
    Error: typeof Error;
    AuthMethodSwitcher: typeof AuthMethodSwitcher;
    PasswordRecovery: typeof PasswordRecovery;
} & typeof LoginForm;

const CustomizableLoginForm = LoginForm as CompositionLoginFormType;

CustomizableLoginForm.Username = UsernameField;
CustomizableLoginForm.Password = PasswordField;
CustomizableLoginForm.Captcha = Captcha;
CustomizableLoginForm.Submit = Submit;
CustomizableLoginForm.RememberMe = RememberMe;
CustomizableLoginForm.Error = Error;
CustomizableLoginForm.AuthMethodSwitcher = AuthMethodSwitcher;
CustomizableLoginForm.PasswordRecovery = PasswordRecovery;

export { useLoginForm };
export default CustomizableLoginForm;
