import {
    Modal,
    VSpacing,
    GridColumn,
    Text,
    Button,
    useBreakpoint,
    BottomSheet,
    NavigationBar,
    BottomSheetFooter,
    Action,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TWO_FACTOR_ARTICLE = `27435`;

interface TwoFactorPopupProps {
    onClose: () => void;
    redirectUrl: string;
}

const TrlKeys = {
    title: 'account.login.modal.twoFactor.title',
    content: 'account.login.modal.twoFactor.content',
    ok: 'account.login.modal.twoFactor.ok',
    knowMore: 'account.login.modal.twoFactor.knowMore',
};

const TwoFactorPopup: TranslatedComponent<TwoFactorPopupProps> = ({ onClose, redirectUrl, trls }) => {
    const site = useSelector((state) => state.topLevelSite);
    const isWebView = useSelector((state) => state.isWebView);
    const { isMobile } = useBreakpoint();

    const okButton = (
        <Button mode="primary" style="accent" size="large" stretched onClick={onClose}>
            {trls[TrlKeys.ok]}
        </Button>
    );

    const knowMoreButton = (
        <Button
            mode="tertiary"
            style="accent"
            size="large"
            Element={Link}
            stretched
            to={
                isWebView
                    ? `/account/login/two_factor_article?backurl=${encodeURIComponent(redirectUrl)}`
                    : `/article/${TWO_FACTOR_ARTICLE}`
            }
            target="_blank"
        >
            {trls[TrlKeys.knowMore]}
        </Button>
    );

    const title = trls[TrlKeys.title];
    const content = (
        <GridColumn xs={4} s={8} m={8} l={6}>
            <Text typography="label-1-regular">{format(trls[TrlKeys.content], { '{0}': site })}</Text>
            <VSpacing default={24} />
        </GridColumn>
    );

    if (isMobile) {
        return (
            <BottomSheet
                visible
                header={<NavigationBar title={title} />}
                footer={<BottomSheetFooter>{[okButton, knowMoreButton]}</BottomSheetFooter>}
                onClose={onClose}
            >
                {content}
            </BottomSheet>
        );
    }

    return (
        <Modal
            actions={<Action mode="secondary" onClick={onClose} icon={CrossOutlinedSize24} />}
            titleSize="large"
            visible
            onClose={onClose}
            title={title}
            footer={<ActionBar primaryActions={[okButton, knowMoreButton]} />}
        >
            {content}
        </Modal>
    );
};

export default translation(TwoFactorPopup);
