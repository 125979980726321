import { FC } from 'react';

import useLoginContext from 'src/components/AccountLogin/hooks/useLoginContext';
import Hhcaptcha from 'src/components/Hhcaptcha';
import Recaptcha from 'src/components/Recaptcha';

const Captcha: FC = () => {
    const { isEmployerForm } = useLoginContext();

    return (
        <>
            <Recaptcha className={isEmployerForm ? 'account-recaptcha' : 'account-login-recaptcha'} />
            <Hhcaptcha />
        </>
    );
};

export default Captcha;
