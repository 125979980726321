import { FC } from 'react';
import { Field } from 'react-final-form';

import useLoginContext from 'src/components/AccountLogin/hooks/useLoginContext';
import { AuthMethod } from 'src/components/AccountLogin/types';
import { useSelector } from 'src/hooks/useSelector';

const HiddenFields: FC = () => {
    const authParams = useSelector((state) => state.authUrl);
    const { loginAs } = useSelector(({ router }) => router.location.query);
    const metaRole = useSelector((state) => state.metaRole);
    const { isEmployerForm, addExternalMethod, authMethod } = useLoginContext();

    return (
        <>
            <input type="hidden" name="failUrl" value={authParams?.failurl} />
            {authMethod === AuthMethod.ByPassword && (
                <input type="hidden" name="accountType" value={isEmployerForm ? 'EMPLOYER' : 'APPLICANT'} />
            )}
            {loginAs && <input type="hidden" name="loginAs" value={loginAs} />}
            {addExternalMethod && <input type="hidden" name="addExternalMethod" value="true" />}
            {metaRole && <input type="hidden" name="role" value={metaRole} />}
            {!!authParams?.['fake-auth-field-name'] && (
                <input
                    type="hidden"
                    name={authParams?.['fake-auth-field-name']}
                    value={authParams?.['fake-auth-field-value']}
                />
            )}
            {!!authParams?.['remember-field-name'] && (
                <Field
                    name={authParams['remember-field-name']}
                    render={({ input }) => <input type="hidden" name={input.name} value={input.value as string} />}
                />
            )}
            <Field
                name="username"
                type="text"
                render={({ input }) => <input type="hidden" name={input.name} value={input.value as string} />}
            />
            <Field
                name="password"
                type="password"
                render={({ input }) => <input type="hidden" name={input.name} value={input.value as string} />}
            />
        </>
    );
};

export default HiddenFields;
