/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { useCaptcha } from '@hh.ru/hhcaptcha';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import { AuthMethod } from 'src/components/AccountLogin/types';
import { useSelector } from 'src/hooks/useSelector';

export { AuthMethod };

const setLoginError = makeSetStoreField('loginError');

const useAuthMethodSwitch = (initialAuthMethod: AuthMethod = AuthMethod.ByCode) => {
    const vacancyId = useSelector(({ postponedActions }) => postponedActions?.vacancy?.vacancyId);
    const captcha = useCaptcha();
    const [authMethod, setAuthMethod] = useState<AuthMethod>(initialAuthMethod);

    const onByPassword = () => {
        Analytics.sendHHEventButtonClick('authorize_by_password', {
            vacancyId,
        });
        setAuthMethod(AuthMethod.ByPassword);
        captcha.resetCaptcha();
    };

    const onByCode = () => {
        Analytics.sendHHEventButtonClick('authorize_by_code', {
            vacancyId,
        });
        setAuthMethod(AuthMethod.ByCode);
        captcha.resetCaptcha();
        setLoginError(null);
    };

    return {
        authMethod,
        onByPassword,
        onByCode,
    };
};

export default useAuthMethodSwitch;
