import { ButtonMode, Button, Link as UiLink } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';

import VkMiniAppsLinkDisable from 'src/components/AccountLogin/components/VkMiniAppsLinkDisable';

interface PasswordRecoveryProps {
    button?: boolean;
    mode?: ButtonMode;
}

const TrlKeys = {
    passwordRecovery: 'password.recovery',
};

const PasswordRecovery: TranslatedComponent<PasswordRecoveryProps> = ({ trls, button, mode = 'secondary' }) => {
    const rememberPasswordUrl = useSelectorNonNullable(({ authUrl }) => authUrl['remember-password']);
    const passwordRecovery = useSelector((state) => state.loginForm.passwordRecovery);
    const text = trls[TrlKeys.passwordRecovery];

    if (!passwordRecovery) {
        return null;
    }

    const defaultElementParams = {
        Element: Link,
        'data-qa': 'account-login-remember-password',
        to: rememberPasswordUrl,
    };

    let content;
    if (button) {
        content = (
            <Button mode={mode} style="accent" size="large" {...defaultElementParams} stretched>
                {text}
            </Button>
        );
    } else {
        content = (
            <UiLink {...defaultElementParams} typography="label-2-regular">
                {text}
            </UiLink>
        );
    }

    return <VkMiniAppsLinkDisable>{content}</VkMiniAppsLinkDisable>;
};

export default translation(PasswordRecovery);
