import { createContext, useContext } from 'react';

import { TwoFactorCheckStatus, LoginSteps, AuthMethod } from 'src/components/AccountLogin/types';
import { Verification, AccountType } from 'src/models/applicant/auth';

interface LoginContextProps {
    confirmError?: string;
    isSubmitting?: boolean;
    twoFactorTimer?: number;
    username?: string;
    verificationType?: TwoFactorCheckStatus | null;
    verificationStatus?: Verification | null;
    isEmployerForm?: boolean;
    addExternalMethod?: boolean;
    useLoginContext?: boolean;
    twoFactorPopupUrl?: string;
    submitProps?: { 'data-qa': string; disabled: boolean };
    step: LoginSteps;
    setStep?: (step: LoginSteps) => void;
    authMethod: AuthMethod;
    actionName?: string;
    accountType?: AccountType;
    isMultiAccount?: boolean;
    onByCode?: () => void;
    onByPassword?: () => void;
}

export const LoginContext = createContext<LoginContextProps>({ step: LoginSteps.Login, authMethod: AuthMethod.ByCode });

export const useLoginContext = (): LoginContextProps => useContext<LoginContextProps>(LoginContext);

export default useLoginContext;
